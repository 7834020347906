<template src="./SignForm.html">
</template>

<script>
    import CustomSnackbar from "../../components/CustomSnackbar.vue";
    import CustomLoading from "../../components/CustomLoading.vue";
    import Login from "./login/Login.vue";
    import Signup from "./signup/Signup.vue";

    export default {
        name: "SignForm",

        components: {
            CustomSnackbar,
            CustomLoading,
            Login,
            Signup,
        },

        created() {         
            this.$store.commit("auth/clearPerson");
        },

        computed: {

            nationalities() {
                return this.$store.state.lookup.nationalities;
            },
        },

        data: () => ({

            tab: null,
            items: ['تسجيل الدخول', 'إنشاء حساب']
            ,
        }),

        methods: {
           
        }
    };
</script>

<style lang="scss" src="./SignForm.scss">
    
</style>
