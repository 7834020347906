<template src="./Login.html">
</template>

<script>

    export default {
        name: "Login",

        components: {
        },

        created() {
            this.getLookups();
            this.loginInfo.nationalityId = this.$types.libyanNationality;
        },

        computed: {

            nationalities() {
                return this.$store.state.lookup.nationalities;
            },

            //========= Rules ===========//
            checkValueRules() { return this.$validation.checkValueRules; },
            nationalNoRules() { return this.$validation.nationalNoRules; },
        },

        data: () => ({

            valid: true,
            loading: false,

            loginInfo: {
                nationalityId: null,
                username: null,
                password: null,
                recaptchaToken: null
            },

            showPass: false,
        }),

        methods: {

            async recaptcha() {
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()

                // Execute reCAPTCHA with action "login".
                this.loginInfo.recaptchaToken = await this.$recaptcha('login')
                this.login()
                // Do stuff with the received token.
            },

            login() {

                if (this.$refs.loginInfo.validate()) {

                    this.$store.dispatch("auth/login", this.loginInfo)
                        .then(() => {
                            if (this.$store.state.auth.lastRoute) {
                                this.$router.push({ name: this.$store.state.auth.lastRoute });
                            }
                            else
                                this.$router.push("/")
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
                else {
                    this.$store.commit("snackbar/show", { message: "الرجاء التأكد من صحة البيانات", type: "error" })
                }
                return;
            },

            forgotPassword() {
                this.$router.push("/forget-password");
            },
            //============= lookups ================//
            getLookups() {
                this.$store.dispatch("lookup/getNationalities");
            },
        }
    };
</script>
<style lang="scss" >
    .forget-pass .v-btn__content {
        justify-content: right;
    }
</style>
