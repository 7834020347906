<template src="./Signup.html">
</template>

<script>
    import CustomDatePicker from '../../../components/CustomDatePicker.vue';

    export default {
        name: "Signup",

        components: {
            CustomDatePicker
        },

        created() {         
            this.getLookups();
            this.signupInfo.nationalityId = this.$types.libyanNationality;
        },

        computed: {

            nationalities() {
                return this.$store.state.lookup.nationalities;
            },

            //========= Rules ===========//
            checkValueRules() { return this.$validation.checkValueRules; },
            checkPhoneNo() { return this.$validation.checkPhoneNo; },
            nationalNoRules() { return this.$validation.nationalNoRules; },
            maxBirthDate() { return this.$helpers.getMaxMinDateString(new Date(), 0) },
        },

        data: () => ({

            valid: true,

            signupInfo: {
                nationalityId: null,
                nationalNo: null,
                passportNo: null,
                fullName: null,
                fullNameEn: null,
                birthdate: null,
                phoneNo: null,
                phoneOperator: 21891,
                recaptchaToken: null
            },

            phoneOperators: [
                { text: '21891', value: 21891 },
                { text: '21892', value: 21892 },
                { text: '21893', value: 21893 },
                { text: '21894', value: 21894 },
            ],
            showPass: false,
            verify: false,
            personId: null,
            password: null,
            passwordRecaptchaToken: null,
            resendPasswordRecaptchaToken: null,
            timerCount: 120
        }),

        watch: {

            timerCount: {
                handler(value) {

                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

        },

        methods: {

            async recaptcha() {
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()

                // Execute reCAPTCHA with action "login".
                this.signupInfo.recaptchaToken = await this.$recaptcha('signup')
                this.signup()
                // Do stuff with the received token.
            },
            signup() {
                if (this.$refs.signupInfo.validate()) {
                    let signupInfo = {
                        nationalityId: this.signupInfo.nationalityId,
                        nationalNo: this.signupInfo.nationalNo,
                        passportNo: this.signupInfo.passportNo,
                        fullName: this.signupInfo.fullName,
                        fullNameEn: this.signupInfo.fullNameEn,
                        birthdate: this.signupInfo.birthdate,
                        phoneNo: `${this.signupInfo.phoneOperator}${this.signupInfo.phoneNo}`,
                        recaptchaToken: this.signupInfo.recaptchaToken
                    }
                    
                    this.$http.signup(signupInfo)
                        .then((res) => {
                            this.verify = true;
                            this.timerCount = 120;
                            this.personId = res.data.personId;
                        })
                        .catch(err => {
                            this.$store.commit("snackbar/show",
                                { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                            );
                        })
                }
                else {
                    this.$store.commit("snackbar/show", { message: "الرجاء التأكد من صحة البيانات", type: "error" })
                }
            },

            async recaptchaResendPassword() {
                await this.$recaptchaLoaded()
                this.resendPasswordRecaptchaToken = await this.$recaptcha(`resend${this.personId}password`)
                this.resendPassword()
            },
            resendPassword() {
                let passowrdInfo = {
                    personId: this.personId,
                    recaptchaToken: this.resendPasswordRecaptchaToken,
                };

                this.$http.resendPassword(passowrdInfo)
                    .then((res) => {
                        this.$store.commit("snackbar/show", { message: res.data.msg, icon: "mdi-check-all", type: "success" });
                        this.timerCount = 120;
                    })
                    .catch(err => {
                        this.$store.commit("snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        );
                    })
            },

            async recaptchaVerifyPassowrd() {
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()

                // Execute reCAPTCHA with action "login".
                this.passwordRecaptchaToken = await this.$recaptcha('verifyPassowrd')
                this.verifyPassowrd()
                // Do stuff with the received token.
            },

            verifyPassowrd() {

                let passowrdInfo = {
                    personId: this.personId,
                    password: this.password,
                    recaptchaToken: this.passwordRecaptchaToken,
                };

                this.$http.verifyPassowrd(passowrdInfo)
                    .then((res) => {
                        this.$store.commit("snackbar/show", { message: "تم تسجيل الدخول بنجاح", icon: "mdi-check-all", type: "success" });

                        this.$store.dispatch("auth/autoLogin", res.data.loginResult)
                            .then(() => {
                                if (this.$store.state.auth.lastRoute) {
                                    this.$router.push({ name: this.$store.state.auth.lastRoute });
                                }
                                else
                                    this.$router.push("/")
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    })
                    .catch(err => {
                        this.$store.commit("snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        );
                    })
            },


            birthdateChanged(date) {
                this.signupInfo.birthdate = date ? date : null;
            },


            //============= lookups ================//
            getLookups() {
                this.$store.dispatch("lookup/getNationalities");
            },
        }
    };
</script>

<style lang="scss" src="./Signup.scss">
</style>